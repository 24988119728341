<template>
  <div class="edit-widgetTemplate-page">
    <BackTitle confirm-click @click="goBack"> Edit widget template </BackTitle>
    <Loader v-if="isLoading" class="mx-auto" color="primary" size="m" />
    <Guard
      v-else-if="widgetTemplate"
      permission="widgetTemplates.edit"
      v-slot="{ isAvailable }"
    >
      <WidgetTemplateForm
        :value="widgetTemplate"
        :is-submitting="isSubmitting"
        :is-disabled="!isAvailable"
        @submit="save"
      />
    </Guard>
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle.vue";
import Guard from "@/components/common/Guard.vue";
import WidgetTemplateForm from "@/components/widget/WidgetTemplateForm";
import { mapActions, mapState } from "vuex";

export default {
  name: "EditWidgetTemplatePage",
  components: { WidgetTemplateForm, Guard, BackTitle },
  data() {
    return {
      isSubmitting: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      widgetTemplate: (state) => state.widgetTemplates.editedItem,
      venue: (state) => state.venues.selectedVenue,
    }),
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchWidgetTemplateById(this.$route.params.id);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      updateWidgetTemplate: "widgetTemplates/updateWidgetTemplate",
      fetchWidgetTemplateById: "widgetTemplates/fetchWidgetTemplateById",
    }),
    async save({ id, ...data }) {
      try {
        this.isSubmitting = true;
        await this.updateWidgetTemplate({ id, data });
        this.goBack();
      } finally {
        this.isSubmitting = false;
      }
    },
    goBack() {
      this.$router.push({
        name: "WidgetTemplates",
      });
    },
    handleOpenBooking(id) {
      this.$router.push({
        name: "BookingDetail",
        params: {
          id: id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.booking-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.edit-widgetTemplate-page {
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;
}
</style>
